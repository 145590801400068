import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "../../index.css";

const ProgramandCampaignHome = ({ programAndCampaignData }) => {
  // Separate data into programs and campaigns
  const programs = programAndCampaignData.filter((item) =>
    item.path.startsWith("/program")
  );
  const campaigns = programAndCampaignData.filter((item) =>
    item.path.startsWith("/campaign")
  );

  // State for managing active tab
  const [activeTab, setActiveTab] = useState("programs");

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Default for larger screens
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Adjust for mobile screens
        settings: {
          slidesToShow: 1, // Show one slide on mobile
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4">
      <div className="program-and-campaign-section mt-10 ">
        {/* Tab Navigation */}
        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => setActiveTab("programs")}
            className={`px-4 py-2 rounded ${
              activeTab === "programs" ? "bg-navy-blue text-white" : "bg-gray-200"
            }`}
          >
            Programs
          </button>
          <button
            onClick={() => setActiveTab("campaigns")}
            className={`px-4 py-2 rounded ${
              activeTab === "campaigns" ? "bg-navy-blue text-white" : "bg-gray-200"
            }`}
          >
            Campaigns
          </button>
        </div>

        {/* Display Content Based on Active Tab */}
        {activeTab === "programs" && (
          <>
            {programs.length > 0 ? (
              <Slider {...settings}>
                {programs.map((item) => (
                  <div key={item.id} className="flex justify-center">
                    <div className="program-card flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transform transition-transform duration-300 hover:scale-105 dark:border-gray-700 dark:bg-gray-800">
                      <div className="w-full h-48 md:w-48 md:h-48 overflow-hidden rounded-lg md:rounded-none md:rounded-l-lg">
                        <img
                          className="object-cover w-full h-full"
                          src={item.bannerImage.url}
                          alt={item.bannerImage.alt || item.title}
                        />
                      </div>
                      <div className="flex flex-col justify-between p-6 leading-normal">
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                          {item.title}
                        </h5>
                        <Link
                          to={item.path}
                          className="inline-block mt-4 px-4 py-2 bg-navy-blue text-white rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p className="text-center text-lg">No programs available</p>
            )}
          </>
        )}

        {activeTab === "campaigns" && (
          <>
            {campaigns.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {campaigns.map((item) => (
                  <div
                    key={item.id}
                    className="campaign-card flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-transform transform duration-300 hover:scale-105"
                  >
                    <div className="w-full h-48 md:w-48 md:h-48 overflow-hidden rounded-lg md:rounded-none md:rounded-l-lg">
                      <img
                        className="object-cover w-full h-full"
                        src={item.bannerImage.url}
                        alt={item.bannerImage.alt || item.title}
                      />
                    </div>
                    <div className="flex flex-col justify-between p-6 leading-normal">
                      <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        {item.title}
                      </h5>
                      <Link
                        to={item.path}
                        className="inline-block mt-4 px-4 py-2 bg-navy-blue text-white rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-lg">No campaigns available</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramandCampaignHome;
