import React from "react";
import { Link } from "react-router-dom";

const LeadershipList = ({ leadershipData, currentLanguage }) => {
  return (
    <section>
      <div className="bg-gray-300">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap pt-5">
            {leadershipData.map((leader) => {
              if (leader.group && leader.group.name === "Director") {
                return (
                  <div
                  key={leader.id}
                  className="md:w-1/3 mb-5  px-3 transition-all duration-300 transform hover:scale-105 hover:shadow-xl focus-within:scale-105 relative"
                >
                  <Link
                    className="group mt-[45px] block relative text-center overflow-hidden"
                    to={`/${currentLanguage === 'hi' ? 'hi/' : ''}leadership/${leader.path.split("/").pop()}`}
                  >
                    <div className="right-border-around relative w-full pt-15 pr-15 pb-0 pl-0">
                      {/* Blurred Border */}
                      <div className="absolute inset-0 rounded-lg blur-sm bg-gray-300 opacity-0 transition-all duration-300 group-hover:opacity-70 group-hover:backdrop-blur-sm"></div>
                      
                      {/* Image */}
                      <img
                        src={leader.imageAbout.url}
                        alt={leader.imageAbout.alt}
                        className="relative w-full z-10 rounded-lg"
                      />
                    </div>
                    {/* Content */}
                    <h2 className="text-[18px] text-[#263238] font-bold p-[40px_10px_20px] mt-[-20px] bg-[#ffffff] relative z-10">
                      {leader.title}
                      <span className="text-base font-medium mt-1 block text-gray-700 h-12 overflow-hidden">
                        {leader.post}
                      </span>
                      <span className="text-base mt-1 block text-gray-700 font-normal h-12 overflow-hidden">
                        {leader.shortDescription}
                      </span>
                    </h2>
                  </Link>
                </div>
                
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container mx-auto px-4 pt-[40px]">
          <ul className="box-border">
            {leadershipData.map((leader, index) => {
              if (leader.group && leader.group.name === "CTO") {
                return (
                  <>
                    <li
                      key={leader.id}
                      className="text-[15px] text-[#263238] mb-[40px]  min-h-[265px] relative"
                    >
                      <div
                        className={`right-border-around relative pt-15 pr-15 pb-0 pl-0 ${
                          index % 2 === 0
                            ? "float-left mr-[40px]"
                            : "float-right ml-[40px]"
                        }`}
                      >
                        <img
                          src={leader.imageAbout.url}
                          alt={leader.imageAbout.alt}
                          className="relative w-[250px] z-10"
                        />
                      </div>
                      <h2 className="text-[22px] text-[#263238] mb-4">
                        <strong>{leader.title}</strong>
                      </h2>
                      <div
                        className="mb-14 text-justify"
                        dangerouslySetInnerHTML={{ __html: leader.body.value }}
                      />
                    </li>
                    <hr className="border-b mb-4 border-[#bcbcbc]"></hr>
                  </>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LeadershipList;
