import React, { useState, useRef, useEffect } from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const VideosContent = ({ node }) => {
  const { title, bannerImage, videoSection } = node;

  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    // Load YouTube API
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
      }
    };

    loadYouTubeAPI();
  }, []);

  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      videoSection.forEach((video, index) => {
        const videoId = video.videoLinkEmbed.mediaOembedVideo.split("v=")[1];
        videoRefs.current[index] = new window.YT.Player(`video-${index}`, {
          videoId,
          events: {
            onStateChange: (event) => handlePlayerStateChange(event, index),
          },
        });
      });
    };

    if (window.YT && window.YT.Player) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      // Cleanup: destroy all video instances
      videoRefs.current.forEach((player) => {
        if (player && player.destroy) {
          player.destroy();
        }
      });
      videoRefs.current = [];
    };
  }, [videoSection]);

  const handlePlayerStateChange = (event, index) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      if (currentPlayingVideo !== null && currentPlayingVideo !== index) {
        videoRefs.current[currentPlayingVideo]?.pauseVideo();
      }
      setCurrentPlayingVideo(index);
    }
  };

  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="container mx-auto px-4">
        <h1 className="text-xl sm:text-xl lg:text-2xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-6 sm:h-6 lg:h-8 relative z-10 bg-white p-1 sm:p-2"
            />
          </div>
        </h1>

        {/* Video Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {videoSection?.map((video, index) => (
            <div
              key={video.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div className="video-wrapper">
                <div id={`video-${index}`} className="w-full h-56"></div>
              </div>
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{video.titleOfVideo}</h2>
                <p className="text-gray-600 mb-4">{video.secondDescription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideosContent;
