import { gql } from '@apollo/client';

const FormQueryEn = gql`
query MyQuery {
  nodeWebforms(langcode: "en", first: 100) {
    nodes {
      bannerImage {
        url
        alt
      }
      id
      jobPost {
        ... on ParagraphCareersPost {
          id
          location
          positions
          postName
          description {
            value
          }
        }
      }
      title
      path
      __typename
    }
  }
}
`;
export default FormQueryEn;