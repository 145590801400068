import React, { useState } from "react";
import Slider from "react-slick";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import "../../index.css";
import { useBaseUrl } from "../../contexts/BaseUrlContext";
import UmangMannualForm from "../Form/UmangMannualForm";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ProgramandCampaignContent = ({ node, currentLanguage, umangData }) => {
  const BASE_URL = useBaseUrl();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const {
    title,
    body,
    bannerImage,
    uploadImage,
    secondTitle,
    thirdTitle,
    thirdSectionDescription,
    video,
    path,
  } = node;

  // Map images for lightbox
  const images = uploadImage.map((image) => ({
    src: image.mediaImage1.url,
    alt: image.mediaImage1.alt,
  }));

  return (
    <div className="relative z-0">
      {bannerImage && (
        <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt} />
      )}
      <div className="container mx-auto px-4">
        <h1 className="text-xl sm:text-xl lg:text-2xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center  line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-6 sm:h-6 lg:h-8  p-1 sm:p-2"
            />
          </div>
        </h1>
        <div className="lg:inline-block sm:block xs:block text-[15px] text-justify">
          {uploadImage && uploadImage.length > 0 && (
            <div className="float-none sm:float-right w-full sm:w-[380px] sm:ml-[50px] mb-[30px]">
              <Slider {...slickSettings}>
                {uploadImage.map((image, index) => (
                  <div key={index} className="item">
                    <div
                      className="relative cursor-pointer"
                      onClick={() => {
                        setPhotoIndex(index);
                        setLightboxOpen(true);
                      }}
                    >
                      <img
                        src={image.mediaImage1.url}
                        alt={image.mediaImage1.alt}
                        className="rounded-xl"
                      />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-50 rounded-xl">
                        <svg
                          className="w-10 h-10"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h4v-4h1v4h4v1h-4v4h-1v-4h-4v-1z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: body.value }} />
        </div>
      </div>

      {/* Umang Manual Form Section */}
      <section className="my-4">
        <div>
          {path === "/campaigns/shiksha-umang" ||
          path === "/hi/campaigns/shiksha-umang" ? (
            <UmangMannualForm
              umangData={umangData}
              currentLanguage={currentLanguage}
            />
          ) : null}
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#eceff1",
          backgroundImage: `url("${BASE_URL}/images/flowerinbg.png")`,
          backgroundPosition: "-120px 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="mx-auto px-[40px] my-4">
          <h2 className="text-xl sm:text-xl lg:text-2xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
            {secondTitle}
            <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
              <img
                src={SvgShikshaFlower}
                alt="headingflower"
                className="h-6 sm:h-6 lg:h-8  p-1 sm:p-2"
              />
            </div>
          </h2>
          <div className="mt-[30px] responsive-video mx-auto">
            <img
              src={require("../laptop.png")}
              className="w-full"
              alt="laptop"
            />
            {video && (
              <video controls>
                <source src={video.mediaVideoFile.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </section>

      {path !== "/campaigns/shiksha-umang" &&
      path !== "/hi/campaigns/shiksha-umang" ? (
        <section>
          <div className="container mx-auto px-4">
            <h3 className="text-xl sm:text-xl lg:text-2xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
              {thirdTitle}
              <div className="relative flex items-center justify-center  line-with-image">
                <img
                  src={SvgShikshaFlower}
                  alt="headingflower"
                  className="h-6 sm:h-6 lg:h-8  p-1 sm:p-2"
                />
              </div>
            </h3>
            <p className="text-justify mb-[20px]">{thirdSectionDescription}</p>
          </div>
        </section>
      ) : null}

      {/* Lightbox Integration */}
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={images}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </div>
  );
};

export default ProgramandCampaignContent;
