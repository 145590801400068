import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReachandImpactContent from './ContentView/ReachandImpactContent';
import WorkingModelContent from './ContentView/WorkingModelContent';
import VisionandMissionContent from './ContentView/VisionandMissionContent';
import ProgramandCampaignContent from './ContentView/ProgramandCampaignContent';
import NodeContent from './ContentView/NodeContent';
import LeadershipDetail from './ContentView/LeadershipDetail';
import NewsletterDetail from './ContentView/NewsletterDetail';
import EventsDetail from './ContentView/EventsDetail';
import StoriesDetail from './ContentView/StoriesDetail'; 
import CareerForm from './Form/CareerForm';
import VideosContent from './ContentView/VideosContent';
import AnnualReportContent from './ContentView/AnnualReportContent';
import HomePage from './ContentView/HomePage';
import NotFound from './NotFound';
import Forbidden from './Forbidden';
import ServerError from './ServerError';

const getNodeComponent = (node, baseUrl, isMobileMenuOpen, currentLanguage, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData) => {
  switch (node.__typename) {
    case 'NodeReachAndImpact':
      return <ReachandImpactContent node={node} baseUrl={baseUrl} isMobileMenuOpen={isMobileMenuOpen} currentLanguage={currentLanguage} />;
    case 'NodeWorkingModel':
      return <WorkingModelContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeVissionAndMission':
      return <VisionandMissionContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeProgramAndCampaign':
      return <ProgramandCampaignContent node={node} baseUrl={baseUrl} umangData={umangData} currentLanguage={currentLanguage} />;
      case 'NodeVideo' : 
      return <VideosContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
      case 'NodeAnnualReport' :
      return <AnnualReportContent node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
    case 'NodeWebform':
        return <CareerForm node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />;
      case 'NodeHomePage': 
      return <HomePage baseUrl={baseUrl} homePageData={homePageData} currentLanguage={currentLanguage} eventsData={eventsData} programAndCampaignData={programAndCampaignData}/>;
    default:
        return (
          <NodeContent
            node={node}
            baseUrl={baseUrl}
            currentLanguage={currentLanguage}
            leadershipData={leadershipData}
            newsletterData= {newsletterData}
            eventsData={eventsData}
            storiesData={storiesData}
          />
        );
  }
};

const RoutesMapper = ({ matchedNodes, currentLanguage, isMobileMenuOpen, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData}) => {
  return (
    <HelmetProvider>
      <Routes>
        <Route path={currentLanguage === 'en' ? "/leadership/:leaderId" : "/hi/leadership/:leaderId"} element={<LeadershipDetail leadershipData={leadershipData} currentLanguage={currentLanguage}/>} />
        <Route path={currentLanguage === 'en' ? "/events/:eventsId" : "/hi/events/:eventsId"} element={<EventsDetail eventsData={eventsData} currentLanguage={currentLanguage}/>} />
        <Route path={currentLanguage === 'en' ? "/stories/:storiesId" : "/hi/stories/:storiesId"} element={<StoriesDetail storiesData={storiesData} currentLanguage={currentLanguage}/>} />
        <Route path={currentLanguage === 'en' ? "/newsletter-3/:newsletterId" : "/hi/newsletter-3/:newsletterId"} element={<NewsletterDetail newsletterData={newsletterData} currentLanguage={currentLanguage}/>} />
        <Route path={currentLanguage === 'en' ? "/" : "/hi"} element={<HomePage homePageData={homePageData} eventsData={eventsData} currentLanguage={currentLanguage} programAndCampaignData={programAndCampaignData}/>} />

        
        {matchedNodes.map(({ menuItem, matchedNodes }, index) => (
          matchedNodes.map((node, nodeIndex) => {
            const path = menuItem.childrenUrls && menuItem.childrenUrls[nodeIndex]
              ? currentLanguage === 'en'
                ? menuItem.childrenUrls[nodeIndex]
                : `/hi${menuItem.childrenUrls[nodeIndex].replace(/^\/hi/, '')}`
              : currentLanguage === 'en'
                ? menuItem.url
                : `/hi${menuItem.url.replace(/^\/hi/, '')}`;
            return (
              node && (
                <Route
                  key={`${index}-${nodeIndex}`}
                  path={path}
                  element={
                    <>
                      <Helmet>
                        <title>{node.title}</title>
                      </Helmet>
                      {getNodeComponent(node, node.path, isMobileMenuOpen, currentLanguage, leadershipData, eventsData, storiesData, umangData, newsletterData, homePageData, programAndCampaignData)}
                    </>
                  }
                />
              )
            );
          })
        ))}
        <Route path="/403" element={<Forbidden />} />
        <Route path="/500" element={<ServerError />} />
        <Route path="*" element={<NotFound />} /> 
      </Routes>
    </HelmetProvider>
  );
};

export default RoutesMapper;
