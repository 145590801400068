import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import pagenotfound from "./pagenotfound.svg";

const NotFound = () => {
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    // Set a timer to display the NotFound page after 3 seconds
    const timer = setTimeout(() => {
      setShowNotFound(true);
    }, 4000); // 4000ms = 4 seconds

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // Render nothing while the timer is active
  if (!showNotFound) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center md:flex-row">
      {/* Left Column: SVG */}
      <div className="flex justify-center items-center w-full md:w-1/2">
        <img src={pagenotfound} alt="Page Not Found" className="w-3/4 max-w-md" />
      </div>

      {/* Right Column: Text and Button */}
      <div className="flex flex-col justify-center items-center w-full md:w-1/2">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-lg text-gray-600 mb-6">Oops! The page you're looking for doesn't exist.</p>
        <Link
          to="/"
          className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
