import { gql } from '@apollo/client';

const VideosQueryEn = gql`
query MyQuery {
  nodeVideos(first: 100) {
    nodes {
      id
      path
      title
      __typename
      bannerImage {
        url
        alt
      }
      videoSection {
        ... on ParagraphVideoLink {
          id
          secondDescription
          titleOfVideo
          videoLinkEmbed {
            ... on MediaRemoteVideo {
              id
              name
              path
              mediaOembedVideo
            }
          }
        }
      }
    }
  }
}
`;
export default VideosQueryEn;
